import React from 'react'
import ContactForm from './contactForm'

const Contact = () => {

    return (
        <>
            <ContactForm />
        </>
    )
}

export default Contact